<template>
    <div>
        <el-upload
            ref="uploadVideos"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-change="handleChange"
            :before-remove="handleBeforeRemove"
            :on-remove="handleRemove"
            multiple
            :auto-upload="true"
            :action="url"
            accept="video/*"
            :headers="headers"
            :fileList="defaultFiles"
            :on-error="errorHandle"
            :before-upload="beforeImageUpload"
            :on-success="uploadSuccessHandle"
            :disabled="disabled"
        >
        <i class="el-icon-plus"></i>
            <template #file="{ file }">
                <div class="video-container" v-if="file.status == 'success' && parseInt(file.percentage) == 100">
                    <video
                        :src="file.url"
                        v-if="file.status == 'success'"
                        class="el-upload-list__item-thumbnail"
                    >
                    </video>
                    <a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{ file.name }}</a>
                    <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-check"></i></label>
                    <i class="el-icon-close"></i>
                    <i class="el-icon-close-tip">press delete to remove</i>
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview">
                            <i class="el-icon-video-play" @click="handleVideoPlayPreview(file)"></i>
                        </span>
                        <span class="el-upload-list__item-delete">
                            <i class="el-icon-delete" @click="handleBeforeRemove(file,defaultFiles)"></i>
                        </span>
                    </span>
                </div>
                <div v-else-if="parseInt(file.percentage)">
                    <el-progress type="circle" :percentage="parseInt(file.percentage)" v-if="parseInt(file.percentage)"/>
                </div>
                <div class="video-container" v-else>
                    <video
                        :src="file.url"
                        class="el-upload-list__item-thumbnail"
                    >
                    </video>
                    <a class="el-upload-list__item-name">
                        <i class="el-icon-document"></i>{{ file.name }}
                    </a>
                    <label class="el-upload-list__item-status-label">
                        <i class="el-icon-upload-success el-icon-check"></i>
                    </label>
                    <i class="el-icon-close"></i>
                    <i class="el-icon-close-tip">press delete to remove</i>
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview">
                            <i class="el-icon-video-play" @click="handleVideoPlayPreview(file)"></i>
                        </span>
                        <span class="el-upload-list__item-delete">
                            <i class="el-icon-delete" @click="handleBeforeRemove(file,defaultFiles)"></i>
                        </span>
                    </span>
                </div>
            </template>
        </el-upload>

        <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image"/>
        </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { MessageBox, Message } from "element-ui";

export default {
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            url: process.env.VUE_APP_BASE_URL + '/product/video/add',
            headers: {},
            isDurationIssue:false,
            isFormatIssue:false,
            isFileSizeIssue:false,
        }
    },
    props:{
        setFiles:{
            type: Function
        },
        value:{
            type: Array,
            required: true,
            description: 'Files'
        },
        defaultFiles: {
            type: Array,
            default: ()=>[],
            description: ''
        },
        disabled:{
            type:Boolean,
            default: false
        }
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
        }),
    },
    methods:{
        async beforeImageUpload(rawFile){

            if(rawFile.length > 5){

                this.$notify({
                    type: "error",
                    title: "Alert",
                    message: "You are limited to uploading a maximum of 5 videos at a time.",
                }); 

                return false

            }

            const video = document.createElement('video');
            const objectURL = URL.createObjectURL(rawFile);
            
            video.src = objectURL;

            let duration = await this.loadVideoMetadata(video);
            
            if(rawFile.size > 52428800){

                this.isFileSizeIssue = true;
                this.$refs.uploadVideos.upload.abort();
            }

            if(rawFile.type == 'video/quicktime' || rawFile.type == 'video/mp4'){

                if(parseInt(duration) >= 60){

                    this.isDurationIssue = true

                    this.$refs.uploadVideos.upload.abort();

                    return false;

                }else{

                    return true;

                }

            }else{

                this.isFormatIssue = true;
                this.$refs.uploadVideos.upload.abort();

                return false;

            }
        },
        loadVideoMetadata(video){

            return new Promise((resolve, reject) => {

                video.addEventListener('loadedmetadata', () => {

                    resolve(video.duration);

                });

                video.addEventListener('error', (error) => {

                    reject(error);

                });

                video.load();

            });
            
        },
        handleChange(file, fileList){
 
            this.setFiles(fileList);

        },
        handlePictureCardPreview(file){

            this.$emit('preview-event',file);

        },
        async errorHandle(error,file,fileList){

            const errors = JSON.parse(error.message);

            if(errors.error.includes("Invalid")){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: errors.error,
                });

            }else if(errors.error.includes("storage limit has been reached")){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: errors.error,
                });

            }else if(errors.error.includes("limited")){

                this.$notify({
                    type: 'error',
                    title: 'Alert',
                    message: errors.error,
                });

            }else{

                this.$notify({
                    type: 'error',
                    title: 'Alert',
                    message: errors.message,
                });

            }
        },
        async handleBeforeRemove(file,fileList){

            if(file.status == 'ready' && file.percentage == 0){

                 return new Promise((resolve, reject) => {

                    if(this.isFileSizeIssue){

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "The video size must not exceed 50MBs.",
                        });

                        let filteredList = fileList.filter(fileItem => {

                            if(fileItem.name != file.name){

                                return fileItem;

                            }

                        });

                        this.$refs.uploadVideos.uploadFiles = filteredList;

                        this.handleChange(file,filteredList);

                        this.isFileSizeIssue = false;

                    }else if(this.isDurationIssue){

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "The allowed video duration is limited to one minute (60 seconds).",
                        });

                        let filteredList = fileList.filter(fileItem => {

                            if(fileItem.name != file.name){

                                return fileItem;

                            }

                        });

                        this.$refs.uploadVideos.uploadFiles = filteredList;

                        this.handleChange(file,filteredList);

                        this.isDurationIssue = false;

                    }else if(file.raw.type != 'video/quicktime' && file.raw.type != 'video/mp4'){

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "Uploaded file must be a video.",
                        });

                        let filteredList = fileList.filter(fileItem => {

                            if(fileItem.name != file.name){

                                return fileItem;

                            }

                        });

                        this.$refs.uploadVideos.uploadFiles = filteredList;

                        this.handleChange(file,filteredList);

                        this.isFormatIssue = false;

                    }else if(file.raw.size > 52428800){

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "The video size must not exceed 50MBs.",
                        });

                        let filteredList = fileList.filter(fileItem => {

                            if(fileItem.name != file.name){

                                return fileItem;

                            }

                        });

                        this.$refs.uploadVideos.uploadFiles = filteredList;

                        this.handleChange(file,filteredList);

                        this.isFileSizeIssue = false;

                    }else if(this.isFormatIssue){

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "Uploaded file must be a video.",
                        });

                        let filteredList = fileList.filter(fileItem => {

                            if(fileItem.name != file.name){

                                return fileItem;

                            }

                        });

                        this.$refs.uploadVideos.uploadFiles = filteredList;

                        this.handleChange(file,filteredList);

                        this.isFormatIssue = false;

                    }
                });
            }else if(file.raw && (file.raw.type == 'video/quicktime' || file.raw.type == 'video/mp4')){

                return new Promise((resolve, reject) => {

                    this.$confirm(
                        `Are you sure? video will be deleted permanently`, 
                        "Confirmation",
                        {
                            confirmButtonText: "Delete",
                            cancelButtonText: "Cancel",
                            type: "warning",
                        }
                    ).then(() => {

                        this.handleRemove(file,this.defaultFiles);

                    })
                    .catch(() => {

                        reject(); 

                    });

                });

            }else{

                if(file.name && ! file.raw){

                    return new Promise((resolve, reject) => {

                        this.$confirm(
                            `Are you sure? video will be deleted permanently`, 
                            "Confirmation", 
                            {
                                confirmButtonText: "Delete",
                                cancelButtonText: "Cancel",
                                type: "warning",
                            }
                        ).then(() => {

                            this.handleRemove(file,this.defaultFiles);

                        }).catch(() => {

                            reject();

                        });

                    });

                }

            }
        },
        uploadSuccessHandle(response){

            this.$store.commit('settings_module/update_remaining_space',response.remaining_space.usage_space);

        },
        handleVideoPlayPreview(file){

            this.$emit('preview-event',file);

        },
        async handleRemove(file, fileList){
            
            if(file.raw && (file.raw.type == 'video/quicktime' || file.raw.type == 'video/mp4')){

                let formData = new FormData();

                if(file.response){

                    formData.append('video',file.response.video);

                }else{

                    formData.append('video',file.name);

                }
                
                try{
                    let res = await this.$axios.post('/product/video/remove/single',formData);
                    if(res.data.status_code == "5001"){

                        let filteredList = fileList.filter(fileItem => {

                            if(fileItem.name != file.name){

                                return fileItem;

                            }

                        });

                        this.$refs.uploadVideos.uploadFiles = filteredList;

                        this.handleChange(file,filteredList);

                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    }
                }catch(error){

                    if(error.response){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }
            }else{

                if(file.name && !file.raw){

                    let formData = new FormData();

                    formData.append('video',file.name);
                    formData.append('stored',1);
                    try{
                        let res = await this.$axios.post('/product/video/remove/single',formData);
                        if(res.data.status_code == "5001"){

                            let filteredList = fileList.filter(fileItem => {

                                if(fileItem.name != file.name){

                                    return fileItem;

                                }

                            });

                            this.$refs.uploadVideos.uploadFiles = filteredList;

                            this.handleChange(file,filteredList);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        }
                    }catch(error){

                        if(error.response){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.message,
                            });
                            
                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });
                            
                        }
                        
                    } 
                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Unsupported file format.",
                    });

                }

            }

        },
    },
    mounted(){

        this.headers["Authorization"] = `Bearer ${this.$store.state.user.token}`
        
    }
}
</script>

<style scoped>

</style>